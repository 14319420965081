import { models } from 'feathers-vuex'
import { reactive, ref, computed, shallowRef, shallowReactive } from '@vue/composition-api'

export let actions = null

/**
 * OLD Managerial state to coordinate the shared map
 */
const hasMapMovedOnce = ref(false)
export const currentMapWrapperId = ref(null)
export const mapWillTransferToId = ref(null)
export const mapState = reactive({
  currentMapWrapperId,
  mapWillTransferToId,
  location: null,
  mapClickState: null,
})
export function prepareMapTransfer({ id }) {
  checkId(id)
  mapWillTransferToId.value = id.value
}
export function finishMapTransfer({ props }) {
  currentMapWrapperId.value = mapWillTransferToId.value
  hasMapMovedOnce.value = true
}
export function updateMapAfterTransfer({ props }) {
  // isMapReady was moved into MapMain.vue, so we'll need a new one for this.
  isMapReady.value && vueMapbox.map.resize()
  Object.assign(mapProps.value, props)
}
function checkId(id) {
  if (!id.value) {
    throw new Error('id must be a ref')
  }
}

const maps = reactive({
  level: 'environment',
  environment: {
    default: null,
    current: null,
  },
})

/**
 * mapProps are directly used by the map.
 */
export const mapProps = ref(null)

/**
 * Called by AppView to initialize, then by MapMain, Discover, and FeatureLayout.
 */
export function useMap({}) {
  return {
    mapState,
    mapProps,
    currentMapWrapperId,
    mapWillTransferToId,
    prepareMapTransfer,
    finishMapTransfer,
    updateMapAfterTransfer,
  }
}
