<template>
  <Dock>
    <div id="page" class="text-center mt-2" style="min-height: 200px">
      Map Wrapper
      <MapWrapper
        name="cannonville"
        class="h-64 w-64"
        :center="[-112.7673352712477, 37.116325303409965]"
        :zoom="12"
      />
    </div>
  </Dock>
</template>
<script>
import { Dock } from '@rovit/dock'
import MapWrapper from './MapWrapper'

export default {
  name: 'MapTest',
  metaInfo: {
    title: 'MapTest'
  },
  components: {
    Dock,
    MapWrapper
  },
  setup(props, context) {
    return {}
  }
}
</script>
