<template>
  <div :id="`mapbox--${id}`" class="map-container">
    <div class="map-wrapper">
      <div
        v-show="isMapReady && isCurrentWrapper"
        :id="`map-container--${id}`"
        ref="mapContainer"
        class="map"
      ></div>

      <div
        v-show="!isCurrentWrapper"
        ref="mapPlaceholder"
        class="map-placeholder relative"
        @click="handlePlaceholderClick"
      >
        <img
          v-if="screenshot != ''"
          :src="screenshot"
          class="absolute top-0 bottom-0 left-0 right-0 w-full h-full object-cover"
        />
        <div
          class="absolute top-0 bottom-0 left-0 right-0 flex flex-row items-center justify-center"
        >
          <p class="bg-gray-500 p-2 rounded-md">Click me to interact with the map</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ObjectID from 'isomorphic-mongo-objectid'
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import {
  vueMapbox,
  isMapReady,
  currentMapWrapperId,
  mapWillTransferToId,
  prepareMapTransfer,
  finishMapTransfer,
  updateMapAfterTransfer,
} from './map-state.js'
import html2canvas from 'html2canvas'

/**
 * This component consists of the outer `.map-wrapper` element which contains
 *   - a `div.map-container` that can receive the mapbox element with `element.appendChild()`
 *   - a `div.map-placeholder` fake map display, while the real mapbox instance shows in another MapWrapper.
 *
 * Use the `starts-with-map` property to make a MapWrapper load with the map in place.
 *
 * Moving the map to another instance is initialized on the placeholder instance, which "pulls" the map into
 * its container through the following process.
 *
 *   1. An event handler on the `.map-placeholder` calls the `prepareMapTransfer` function with its `id`.
 *   2. The `prepareMapTransfer` function sets the `mapWillTransferToId` property.
 *   3. The watcher on `mapWillTransferToId` tells the current component when it's time to transition away from
 *      holding the real map instance. If its `id` matches the `currentMapWrapperId` and the `mapWillTransferToId`
 *      is a different id, it needs to swap out the real map for a placeholder.
 *   4. Once the current map wrapper finishes its transition to a placeholder, it calls `finishMapTransfer()`.
 *   5. The `finishMapTransfer` function copies the `mapWillTransferToId.value` to the `currentMapWrapperId`.
 *   6. A watcher on the `currentMapWrapperId` checks if its own `id` matches the `currentMapWrapperId`. If there
 *      is a match, it runs `getElementById(`map-container--${id}`).appendChild(mapElement) and the map moves
 *      inside the new current MapWrapper.
 */
export default {
  name: 'MapWrapper',
  props: {
    name: {
      type: String,
      default: '',
    },
    startWithMap: {
      type: Boolean,
      default: false,
    },
    center: { type: Array, default: () => [-112.7673352712477, 37.116325303409965] },
    zoom: { type: Number, default: 8.211764492345896 },
    markers: { type: Array, default: () => [] },
  },
  setup(props, context) {
    const id = ref(new ObjectID().toString())
    context.emit('id-ready', id.value)
    const isCurrentWrapper = computed(() => id.value === currentMapWrapperId.value)
    const screenshot = ref('')

    const mapContainer = ref(null)
    const mapPlaceholder = ref(null)

    if (props.startWithMap) {
      prepareMapTransfer({ id })
    }

    const center = computed(() => props.center)
    const zoom = computed(() => props.zoom)
    const propsForThisMap = computed(() => {
      return Object.assign({}, props, { center, zoom })
    })

    watch(
      () => mapWillTransferToId.value,
      async (newId, oldId) => {
        const name = props.name
        const willBecomeCurrentWrapper = newId != null && newId === id.value
        const mapIsLeavingThisWrapper = oldId != null && oldId === id.value

        /**
         * Swap from the visible map to the actual map.
         *
         * If this instance of MapWrapper matches he `currentMapWrapperId`, then do whatever teardown is
         * necessary to transition to not showing the map.  This is where you would take a screenshot of the
         * element and use it as the background image for the map placeholder.
         */
        if (mapIsLeavingThisWrapper) {
          console.log('map leaving', name)
          if (currentMapWrapperId.value) {
            const img = vueMapbox.map.getCanvas().toDataURL('image/png')
            screenshot.value = img
          }
        }
        /**
         * This map will become the current map.
         */
        if ((!isCurrentWrapper.value || !currentMapWrapperId.value) && willBecomeCurrentWrapper) {
          console.log('map going to', name)
          finishMapTransfer({ props: propsForThisMap })
        }
      },
      { immediate: true }
    )

    onMounted(() => {
      watch(
        () => isCurrentWrapper.value,
        (isCurrent) => {
          const isPresent = mapContainer.value.children.length > 0
          if (isCurrent && !isPresent) {
            const mapEl = document.getElementById('mapbox-floating-map')
            if (mapContainer.value && mapEl) {
              mapContainer.value.appendChild(mapEl)
            }
            updateMapAfterTransfer({ props: propsForThisMap })
          }
        },
        { immediate: true }
      )
    })

    function handlePlaceholderClick() {
      prepareMapTransfer({ id })
    }

    function log(o) {
      console.log(o.substr(o.length - 5))
    }

    return {
      vueMapbox,
      isMapReady,
      mapContainer,
      mapPlaceholder,
      isCurrentWrapper,
      id,
      // getScreenshot,
      // screenshotStore,
      screenshot,
      // screenshotRef,
      currentMapWrapperId,
      handlePlaceholderClick,
      log,
    }
  },
}
</script>

<style scoped>
.map-wrapper {
  height: 100%;
  width: 100%;
  border: solid 1px #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.map {
  cursor: grab;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: beige;
}
.map-placeholder {
  @apply bg-cover;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
